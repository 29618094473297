import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import PageWrapper from "components/PageWrapper";
import ServiceItem from "components/ServiceItem";
import About from "views/Sections/About";

import "../style/pages/services.scss";

const Services = ({ data }) => {
  if (!data) {
    return null;
  }

  const {
    allMarkdownRemark: {
      edges: [{ node: frontmatter }],
    },
  } = data;
  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    imageFileName,
    jumpToAnchors,
    services,
  } = frontmatter.frontmatter;

  return (
    <PageWrapper
      anchor={anchor}
      header={rootHeader}
      subheader={rootSubHeader}
      imageFileName={imageFileName}
    >
      <Row className="buttons-row text-center">
        {jumpToAnchors.map((jumpToAnchor) => (
          <Col md={6} xs={6} key={`services-${jumpToAnchor.toLowerCase()}`}>
            <Link role="button" className="btn btn-outline-secondary" to={`#${jumpToAnchor}`}>
              <span className="button-text">{jumpToAnchor.toUpperCase()}</span>
            </Link>
          </Col>
        ))}
      </Row>
      <Row className="services" id={anchor}>
        {services.map((service, i) => (
          <Col md={12} key={service.header} className="service-item">
            <ServiceItem counter={i} {...service} />
          </Col>
        ))}
      </Row>

      <About className="bg-light" id="about" />
    </PageWrapper>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: "en" }, fileName: { eq: "Services.en" } } }
    ) {
      edges {
        node {
          frontmatter {
            anchor
            header
            subheader
            jumpToAnchors
            imageFileName
            services {
              content
              header
              iconName
              imageFileName
              items
            }
          }
        }
      }
    }
  }
`;

Services.propTypes = {
  data: PropTypes.object,
};

Services.defaultProps = {
  data: null,
};

export default Services;
