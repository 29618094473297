import React from "react";
import PropTypes from "prop-types";

import { Video } from "gatsby-video";

const HeaderVideo = ({ videoFileNames, imageFileNames }) => {
  return (
    <>
      {/* Visible on md, lg, xl */}
      <div className="embed-responsive embed-responsive-16by9 d-none d-md-block">
        <Video
          poster={`images/${imageFileNames.LG}`}
          preload="true"
          loop
          autoPlay
          muted
          sources={[
            {
              aspectRatio: 2,
              fileExtension: "mp4",
              height: 600,
              originalName: "landing.mp4",
              presentationMaxHeight: 600,
              presentationMaxWidth: 1200,
              src: `videos/${videoFileNames.LG}`,
            },
          ]}
        />
      </div>

      {/* Visible on xs, sm */}
      <div className="embed-responsive embed-responsive-1by1 d-xs-block d-sm-block d-md-none">
        <Video
          poster={`images/${imageFileNames.SM}`}
          preload="true"
          loop
          autoPlay
          muted
          sources={[
            {
              aspectRatio: 1.77777777778,
              fileExtension: "mp4",
              height: 667,
              originalName: "landing.mp4",
              presentationMaxHeight: 960,
              presentationMaxWidth: 576,
              src: `videos/${videoFileNames.SM}`,
            },
          ]}
        />
      </div>
    </>
  );
};

HeaderVideo.propTypes = {
  videoFileNames: PropTypes.object.isRequired,
  imageFileNames: PropTypes.object.isRequired,
};

export default HeaderVideo;
