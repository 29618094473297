import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PortfolioItem from "components/PortfolioItem";
import PageSection from "components/PageSection";
import "./Portfolio.scss";

export const query = graphql`
  {
    portfolio: allContentfulProjects {
      nodes {
        title
        slug
        shortDescription {
          raw
        }
        coverImage {
          gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED, aspectRatio: 1.5)
        }
      }
    }
    section: allMarkdownRemark(filter: { fields: { fileName: { eq: "Portfolio.en" } } }) {
      edges {
        node {
          frontmatter {
            anchor
            header
            subheader
          }
        }
      }
    }
  }
`;

const Portfolio = ({ className }) => {
  const data = useStaticQuery(query);

  if (!data) {
    return null;
  }

  const {
    section: {
      edges: [
        {
          node: {
            frontmatter: { anchor, header, subheader },
          },
        },
      ],
    },
    portfolio: { nodes: projects },
  } = data;

  return (
    <PageSection className={clsx("portfolio-section py-5", className)} id={anchor}>
      <Row>
        <SectionHeader header={header} subheader={subheader} />
      </Row>
      <Row className="portfolios">
        {projects.map((project) => (
          <PortfolioItem key={project.slug} {...project} />
        ))}
      </Row>
    </PageSection>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
};

Portfolio.defaultProps = {
  className: "",
};

export default Portfolio;
