import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import PropTypes from "prop-types";

import { Alert } from "react-bootstrap";

import "./CustomAlert.scss";

const CustomAlert = ({ variant, clearStatus }) => {
  const data = useStaticQuery(graphql`
    {
      contact: allMarkdownRemark(
        filter: { fields: { langKey: { eq: "en" }, fileName: { eq: "Contact.en" } } }
      ) {
        edges {
          node {
            frontmatter {
              email
              telephone
            }
          }
        }
      }
    }
  `);

  const {
    contact: {
      edges: [
        {
          node: {
            frontmatter: { email, telephone },
          },
        },
      ],
    },
  } = data;

  let heading;
  let text;

  if (variant === "success") {
    heading = "Thank you for your message!";
    text = `We will get back to you within a few business days. If you have any urgent queries, please call our office at ${telephone}.`;
  } else if (variant === "danger") {
    heading = "Oh snap! We didn't receive your message.";
    text = `Please try again later or send us an email at ${email}.`;
  } else {
    heading = "Sending...";
    text = "";
  }

  return (
    <Alert variant={variant} onClose={() => clearStatus()}>
      {heading && <Alert.Heading className="py-3">{heading}</Alert.Heading>}
      <p>{text}</p>
      <p className="custom-alert-link">
        <Link to="/">Go home</Link>
      </p>
    </Alert>
  );
};

CustomAlert.propTypes = {
  variant: PropTypes.string,
  clearStatus: PropTypes.func,
};

CustomAlert.defaultProps = {
  variant: "danger",
  clearStatus: null,
};

export default CustomAlert;
