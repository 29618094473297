import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import ServiceItemSM from "./ServiceItemSM";
import ServiceItemImage from "../ServiceItemImage";
import ServiceItemText from "../ServiceItemText";
import "./ServiceItem.scss";

const ServiceItem = ({ imageFileName, header, content, items, counter }) => {
  return (
    <>
      {/* Visible on xs, sm */}
      <div className="d-xs-block d-sm-block d-md-none">
        <ServiceItemSM
          imageFileName={imageFileName}
          header={header}
          content={content}
          counter={counter}
          items={items}
        />
      </div>
      {/* Visible on md, lg, xl */}
      <div className="d-none d-md-block py-5">
        <Row className="service-item-row py-5">
          <Col
            md={6}
            className="service-item-heading"
            data-sal="slide-right"
            data-sal-duration="2000"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <ServiceItemImage header={header} imageFileName={imageFileName} />
          </Col>
          <Col
            md={6}
            className="service-item-desc"
            data-sal="slide-left"
            data-sal-duration="1500"
            data-sal-delay="50"
            data-sal-easing="ease"
          >
            <div
              className="line-counter"
              data-sal="zoom-in"
              data-sal-duration="1500"
              data-sal-delay="500"
              data-sal-easing="ease"
            >
              <div className="line-wrapper">
                <div className="line" />
              </div>

              <div className="counter">0{counter + 1}</div>
            </div>
            <h2 className="service-item-header text-center">{header}</h2>
            <ServiceItemText content={content} items={items} />
          </Col>
        </Row>
      </div>
    </>
  );
};

ServiceItem.propTypes = {
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
  items: PropTypes.array,
  counter: PropTypes.number,
};

ServiceItem.defaultProps = {
  imageFileName: null,
  header: "",
  content: "",
  items: [],
  counter: 0,
};

export default ServiceItem;
