import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import { Description, Media, Client, ImageLg, Font, Color } from "views/Project";
import ImageCard from "../components/ImageCard";

import SEO from "../components/SEO";

import "../style/pages/portfolio.scss";

const ProjectTemplate = ({ data }) => {
  if (!data) {
    return null;
  }

  const { project } = data;

  return (
    <div className="portfolio-container">
      <ImageCard imageData={project.coverImage.gatsbyImageData} />
      <Description
        projectDescription={project.projectDescription}
        projectDetails={project.projectDetails}
        primaryColor={project.primaryColor}
      />
      <Media
        mediaDescription={project.mediaDescription}
        videoId={project.vimeoId}
        alt={`media-${project.slug}`}
      />
      <Client
        clientDescription={project.clientDescription}
        clientImage={project.clientImage}
        clientLogo={project.clientLogo}
        alt={`client-${project.slug}`}
        primaryColor={project.primaryColor}
      />
      {project.highlightImage ? (
        <ImageLg image={project.highlightImage} alt={`highlight-${project.slug}`} />
      ) : null}
      {project.bodyFontName && project.bodyFontName ? (
        <Font
          headerFontName={project.headerFontName}
          headerFontWeight={project.headerFontWeight}
          headerFontUrl={project.headerFontFile.url}
          subheaderFontName={project.subheaderFontName}
          subheaderFontWeight={project.subheaderFontWeight}
          subheaderFontUrl={project.subheaderFontFile && project.subheaderFontFile.url}
          bodyFontName={project.bodyFontName}
          bodyFontWeight={project.bodyFontWeight}
          bodyFontUrl={project.bodyFontFile.url}
          bodyText={project.shortDescription}
          primaryColor={project.primaryColor}
          secondaryColor={project.secondaryColor}
        />
      ) : null}
      {project.colorsPalletDescription && project.colorsPalletImage ? (
        <Color
          colorsPalletDescription={project.colorsPalletDescription}
          colorsPalletImage={project.colorsPalletImage}
          alt={`pallet-${project.slug}`}
        />
      ) : null}
      {project.finalImage ? (
        <ImageLg image={project.finalImage} alt={`final-img-${project.slug}`} />
      ) : null}
    </div>
  );
};

export const query = graphql`
  query ($title: String) {
    project: contentfulProjects(title: { eq: $title }) {
      slug
      title
      primaryColor
      secondaryColor
      coverImage {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
      shortDescription {
        raw
      }
      projectDescription {
        raw
      }
      projectDetails {
        raw
      }
      mediaDescription {
        raw
      }
      vimeoId
      clientDescription {
        raw
      }
      clientLogo {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
      clientImage {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
      highlightImage {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
      headerFontName
      headerFontWeight
      headerFontFile {
        url
      }
      subheaderFontName
      subheaderFontWeight
      subheaderFontFile {
        url
      }
      bodyFontName
      bodyFontWeight
      bodyFontFile {
        url
      }
      colorsPalletDescription {
        raw
      }
      colorsPalletImage {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
      finalImage {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
  }
`;

ProjectTemplate.propTypes = {
  data: PropTypes.object,
};

ProjectTemplate.defaultProps = {
  data: {},
};

export default ProjectTemplate;
