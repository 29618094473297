import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { Card } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

import "./ImageCard.scss";

const ImageCard = ({ imageData, heading, subheading, alt }) => {
  return (
    <Card className={clsx("image-card")}>
      <GatsbyImage className="image" image={imageData} alt={alt} />
      {heading !== "" && (
        <Card.Body>
          <h1 className="intro-text">
            <span className="intro-heading">{heading}</span>
            {subheading && <span className="intro-subheading">{subheading}</span>}
          </h1>
        </Card.Body>
      )}
    </Card>
  );
};

ImageCard.propTypes = {
  imageData: PropTypes.object.isRequired,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  alt: PropTypes.string,
};

ImageCard.defaultProps = {
  alt: "",
  heading: "",
  subheading: "",
};

export default ImageCard;
