import React from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";

import ContactForm from "components/ContactForm";
import BGWrapper from "components/BGWrapper";

import "../../style/pages/contact.scss";

const apply = ({ location: { state } }) => {
  let career = "";
  let pageTitle = "Application";
  if (state && state.career) {
    career = state.career;
    pageTitle = `${pageTitle} for ${career}`;
  }

  return (
    <BGWrapper>
      <div />
      <Container className="page-container">
        <Row className="title-row">
          <h2 className="text-center">{pageTitle}</h2>
        </Row>
        <Row className="contact-row mt-5">
          <Col xs={12} md={{ span: 8, offset: 2 }} className="col contact-col">
            <ContactForm career={career} />
          </Col>
        </Row>
      </Container>
    </BGWrapper>
  );
};

apply.propTypes = {
  data: PropTypes.object,
};

apply.defaultProps = {
  data: null,
};

export default apply;
