import React from "react";
import PropTypes from "prop-types";

import { Col } from "react-bootstrap";
import Image from "components/Image";

const ContactItem = ({ iconFileName, text, link }) => {
  let iconPart;
  if (iconFileName) {
    iconPart = <Image className="section-icon" fileName={iconFileName} alt={iconFileName} />;
  }
  return (
    <Col lg={6} className="ml-auto text-center">
      <a className="d-block" href={link}>
        {iconPart}
        <p className="pt-5">{text}</p>
      </a>
    </Col>
  );
};

ContactItem.propTypes = {
  iconFileName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ContactItem;
