import React from "react";
import PropTypes from "prop-types";

import { Col } from "react-bootstrap";
import Image from "components/Image";

import "./AboutItem.scss";

const AboutItem = ({ iconFileName, label, text }) => {
  let iconPart;
  if (iconFileName) {
    iconPart = (
      <Image
        className="section-icon about-icon"
        fileName={iconFileName}
        alt={`${label.toLowerCase()}-icon`}
      />
    );
  }
  return (
    <Col md={4} className="about-item">
      {iconPart}
      <h4 className="about-titile py-5 text-center">{label}</h4>
      <p className="text-justify text-muted">{text}</p>
    </Col>
  );
};

AboutItem.propTypes = {
  iconFileName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default AboutItem;
