import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import ServiceItemImage from "../ServiceItemImage";
import ServiceItemText from "../ServiceItemText";
import "./ServiceItem.scss";

const ServiceItemSM = ({ imageFileName, header, content, items }) => {
  return (
    <>
      <Row className="service-item-row">
        <Col xs={12}>
          <h2 className="service-item-heading text-center pb-5">{header}</h2>
          <ServiceItemImage header={header} imageFileName={imageFileName} />
        </Col>
        <Col xs={12} className="service-item-desc pt-5">
          <div className="line-counter">
            <div className="line-wrapper">
              <div className="line" />
            </div>
          </div>
          <ServiceItemText content={content} items={items} />
        </Col>
      </Row>
    </>
  );
};

ServiceItemSM.propTypes = {
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
  items: PropTypes.array,
};

ServiceItemSM.defaultProps = {
  imageFileName: null,
  header: "",
  content: "",
  items: [],
};

export default ServiceItemSM;
