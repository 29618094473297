import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import VimeoVideo from "components/VimeoVideo";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import options from "../../../utils/richTextOptions";
import "../Project.scss";
import "./Media.scss";

const Media = ({ mediaDescription, videoId, alt }) => {
  return (
    <Row className="project-media">
      <Col md={4}>
        <div className="position-center">
          <div className="markdown">{renderRichText(mediaDescription, options)}</div>
        </div>
      </Col>
      {videoId && (
        <Col md={8} className="p-5">
          <VimeoVideo videoId={videoId} videoTitle={alt} />
        </Col>
      )}
    </Row>
  );
};

Media.propTypes = {
  mediaDescription: PropTypes.object.isRequired,
  videoId: PropTypes.number,
  alt: PropTypes.string,
};

Media.defaultProps = {
  videoId: "",
  alt: "",
};

export default Media;
