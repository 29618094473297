import makeFAIcon from "utils/makeFAIcon";

import {
  faTimes,
  faClock,
  faLocationDot,
  faGlobe,
  faArrowRight,
  faLightbulb,
  faCloud,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faGithubAlt,
  faMediumM,
  faInstagram,
  faVimeo,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";

export const LanguageIcon = makeFAIcon(faGlobe);
export const Lightbulb = makeFAIcon(faLightbulb);
export const Cloud = makeFAIcon(faCloud);
export const CloseIcon = makeFAIcon(faTimes);
export const BusinessHours = makeFAIcon(faClock);
export const Location = makeFAIcon(faLocationDot);
export const ArrowRight = makeFAIcon(faArrowRight);
export const ArrowCircleUp = makeFAIcon(faArrowCircleUp);

export const GithubIcon = makeFAIcon(faGithubAlt);
export const MediumIcon = makeFAIcon(faMediumM);
export const TwitterIcon = makeFAIcon(faTwitter);
export const FacebookIcon = makeFAIcon(faFacebookF);
export const LinkedinIcon = makeFAIcon(faLinkedinIn);
export const InstagramIcon = makeFAIcon(faInstagram);
export const VimeoIcon = makeFAIcon(faVimeo);
export const RedditIcon = makeFAIcon(faReddit);
