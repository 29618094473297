import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Header from "components/Header";
import { Portfolio, About, Clients } from "views/Sections";
import SEO from "components/SEO";
import BGWrapper from "components/BGWrapper";

import "utils/fixFontAwesome";

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    header: allMarkdownRemark(filter: { fields: { fileName: { eq: "Home.en" } } }) {
      nodes {
        frontmatter {
          media {
            header
            subheader
            linkTo
            linkToText
            videoFileNames {
              LG
              SM
            }
            imageFileNames {
              LG
              SM
            }
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    header: {
      nodes: [
        {
          frontmatter: { media },
        },
      ],
    },
  } = data;

  return (
    <BGWrapper>
      <SEO title="Home" keywords={keywords} description={description} />
      <Header {...media} />
      <Portfolio />
      <About className="bg-light" />
      <Clients />
    </BGWrapper>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;
