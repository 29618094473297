import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Navbar from "views/Navbar";
import Footer from "views/Footer";
import BGWrapper from "components/BGWrapper";

import "../style/main.scss";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      footer: allMarkdownRemark(
        filter: { fields: { langKey: { eq: "en" }, fileName: { eq: "Footer.en" } } }
      ) {
        nodes {
          frontmatter {
            copyright
            privacyHref
            privacyText
            social {
              instagram
              facebook
              linkedin
              vimeo
            }
          }
        }
      }
      navbar: allMarkdownRemark(
        filter: { fields: { langKey: { eq: "en" }, fileName: { eq: "Nav.en" } } }
      ) {
        nodes {
          frontmatter {
            brand
            logo
            links
          }
        }
      }
    }
  `);
  const {
    footer: {
      nodes: [{ frontmatter: footerData }],
    },
    navbar: {
      nodes: [{ frontmatter: navbarData }],
    },
  } = data;

  return (
    <BGWrapper>
      <Navbar frontmatter={navbarData} />
      {children}
      <Footer frontmatter={footerData} />
    </BGWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
