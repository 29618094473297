import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Icon from "../Icon";

const PrimaryButton = ({ text, link }) => {
  return (
    <>
      <Link role="button" className="btn btn-outline-primary" to={link}>
        <span className="button-text" data-text={text} />
        <Icon iconName="ArrowRight" className="arrow-icon fa-lg" />
      </Link>
    </>
  );
};

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default PrimaryButton;
