import React from "react";
import PropTypes from "prop-types";

import { Row } from "react-bootstrap";

import { GatsbyImage } from "gatsby-plugin-image";
import "./ImageLg.scss";

const ImageLg = ({ image, alt }) => {
  return (
    <>
      <Row className="lg-image">
        <GatsbyImage image={image.gatsbyImageData} alt={alt} />
      </Row>
    </>
  );
};

ImageLg.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
};

ImageLg.defaultProps = {
  image: null,
  alt: "",
};

export default ImageLg;
