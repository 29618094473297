import React from "react";

import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";

import "../style/pages/policy.scss";

const Policy = () => (
  <Container className="page-container">
    <Row className="title-row">
      <h2 className="text-center">Privacy Policy</h2>
    </Row>
    <Col className="policy-col" md={8} sm={12}>
      <Row>Our Privacy Policy was last updated on 06.05.2022.</Row>
      <Row>
        This Privacy Policy describes our policies and procedures on the collection, use and
        disclosure of Your information when You use the Service and tells You about Your privacy
        rights and how the law protects You.
      </Row>
      <Row>
        We use Your Personal data to provide and improve the Service. By using the Service, You
        agree to the collection and use of information in accordance with this Privacy Policy.
      </Row>
      <Row>
        <strong>Interpretation and Definitions</strong>
      </Row>
      <Row>
        <strong>Interpretation</strong>
      </Row>
      <Row>
        The words of which the initial letter is capitalized have meanings defined under the
        following conditions. The following definitions shall have the same meaning regardless of
        whether they appear in singular or in plural.
      </Row>
      <Row>
        <strong>Definitions</strong>
      </Row>
      <Row>For the purposes of this Privacy Policy:</Row>
      <Row>
        <ul>
          <li>
            <p>
              <strong>&quot;Account&quot;</strong> means a unique account created for You to access
              our Service or parts of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;Company&quot;</strong> (referred to as either &quot;the Company&quot;,
              &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Brand
              Izby s.r.o.
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;Country&quot;</strong> refers to Slovakia.
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;Cookies&quot;</strong> are small files that are placed on Your computer,
              mobile device or any other device by a website, containing the details of Your
              browsing history on that website among its many uses.
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;Device&quot;</strong> means any device that can access the Service such
              as a computer, a cellphone or a digital tablet.
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;Personal Data&quot;</strong> is any information that relates to an
              identified or identifiable individual.
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;Service&quot;</strong> refers to the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;Usage Data&quot;</strong> refers to data collected automatically, either
              generated by the use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;Website&quot;</strong> refers to Brand Izby website, accessible from
              https://brandizby.com
            </p>
          </li>
          <li>
            <p>
              <strong>&quot;You&quot;</strong> means the individual accessing or using the Service,
              or the company, or other legal entity on behalf of which such individual is accessing
              or using the Service, as applicable.
            </p>
          </li>
        </ul>
      </Row>
      <Row>
        <strong>Collecting and Using Your Personal Data</strong>
      </Row>
      <Row>
        <strong>Types of Data Collected</strong>
      </Row>
      <Row>
        <strong>Personal Data</strong>
      </Row>
      <Row>
        While using Our Service, We may ask You to provide Us with certain personally identifiable
        information that can be used to contact or identify You. Personally identifiable information
        may include, but is not limited to:
      </Row>
      <Row>
        <ul>
          <li>
            <p>Email address</p>
          </li>
          <li>
            <p>First name and last name</p>
          </li>
          <li>
            <p>Phone number</p>
          </li>
          <li>
            <p>Address, State, Province, ZIP/Postal code, City</p>
          </li>
          <li>
            <p>Usage Data</p>
          </li>
        </ul>
      </Row>
      <Row>
        <strong>Usage Data</strong>
      </Row>
      <Row>Usage Data is collected automatically when using the Service.</Row>
      <Row>
        Usage Data may include information such as Your Device&apos;s Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our Service that You visit,
        the time and date of Your visit, the time spent on those pages, unique device identifiers
        and other diagnostic data.
      </Row>
      <Row>
        When You access the Service by or through a mobile device, We may collect certain
        information automatically, including, but not limited to, the type of mobile device You use,
        Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating
        system, the type of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </Row>
      <Row>
        We may also collect information that Your browser sends whenever You visit our Service or
        when You access the Service by or through a mobile device.
      </Row>
      <Row>
        <strong>Tracking Technologies and Cookies</strong>
      </Row>
      <Row>
        We use Cookies and similar tracking technologies to track the activity on Our Service and
        store certain information. Tracking technologies used are beacons, tags, and scripts to
        collect and track information and to improve and analyze Our Service. The technologies We
        use may include:
      </Row>
      <Row>
        <ul>
          <li>
            <strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your
            Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie
            is being sent. However, if You do not accept Cookies, You may not be able to use some
            parts of our Service. Unless you have adjusted Your browser setting so that it will
            refuse Cookies, our Service may use Cookies.
          </li>
          <li>
            <strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain
            small electronic files known as web beacons (also referred to as clear gifs, pixel tags,
            and single-pixel gifs) that permit the Company, for example, to count users who have
            visited those pages or opened an email and for other related website statistics (for
            example, recording the popularity of a certain section and verifying system and server
            integrity).
          </li>
        </ul>
      </Row>
      <Row>
        Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline, while Session Cookies
        are deleted as soon as You close Your web browser.
      </Row>
      <Row>We use both Session and Persistent Cookies for the purposes set out below:</Row>
      <Row>
        <ul>
          <li>
            <p>
              <strong>Necessary / Essential Cookies</strong>
            </p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies are essential to provide You with services available through
              the Website and to enable You to use some of its features. They help to authenticate
              users and prevent fraudulent use of user accounts. Without these Cookies, the services
              that You have asked for cannot be provided, and We only use these Cookies to provide
              You with those services.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies Policy / Notice Acceptance Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies identify if users have accepted the use of cookies on the
              Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Functionality Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>
              Purpose: These Cookies allow us to remember choices You make when You use the Website,
              such as remembering your login details or language preference. The purpose of these
              Cookies is to provide You with a more personal experience and to avoid You having to
              re-enter your preferences every time You use the Website.
            </p>
          </li>
          <li>
            <p>
              <strong>Tracking and Performance Cookies</strong>
            </p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Third-Parties</p>
            <p>
              Purpose: These Cookies are used to track information about traffic to the Website and
              how users use the Website. The information gathered via these Cookies may directly or
              indirectly identify you as an individual visitor. This is because the information
              collected is typically linked to a pseudonymous identifier associated with the device
              you use to access the Website. We may also use these Cookies to test new pages,
              features or new functionality of the Website to see how our users react to them.
            </p>
          </li>
        </ul>
      </Row>
      <Row>
        For more information about the cookies we use and your choices regarding cookies, please
        visit our Cookies Policy or the Cookies section of our Privacy Policy.
      </Row>
      <Row>
        <strong>Use of Your Personal Data</strong>
      </Row>
      <Row>The Company may use Personal Data for the following purposes:</Row>
      <Row>
        <ul>
          <li>
            <p>
              <strong>To provide and maintain our Service</strong>, including to monitor the usage
              of our Service.
            </p>
          </li>
          <li>
            <p>
              <strong>To manage Your Account:</strong> to manage Your registration as a user of the
              Service. The Personal Data You provide can give You access to different
              functionalities of the Service that are available to You as a registered user.
            </p>
          </li>
          <li>
            <p>
              <strong>For the performance of a contract:</strong> the development, compliance and
              undertaking of the purchase contract for the products, items or services You have
              purchased or of any other contract with Us through the Service.
            </p>
          </li>
          <li>
            <p>
              <strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a mobile
              application&apos;s push notifications regarding updates or informative communications
              related to the functionalities, products or contracted services, including the
              security updates, when necessary or reasonable for their implementation.
            </p>
          </li>
          <li>
            <p>
              <strong>To provide You</strong> with news, special offers and general information
              about other goods, services and events which we offer that are similar to those that
              you have already purchased or enquired about unless You have opted not to receive such
              information.
            </p>
          </li>
          <li>
            <p>
              <strong>To manage Your requests:</strong> To attend and manage Your requests to Us.
            </p>
          </li>
          <li>
            <p>
              <strong>For business transfers:</strong> We may use Your information to evaluate or
              conduct a merger, divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of Our assets, whether as a going concern or as part
              of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us
              about our Service users is among the assets transferred.
            </p>
          </li>
          <li>
            <p>
              <strong>For other purposes</strong>: We may use Your information for other purposes,
              such as data analysis, identifying usage trends, determining the effectiveness of our
              promotional campaigns and to evaluate and improve our Service, products, services,
              marketing and your experience.
            </p>
          </li>
        </ul>
      </Row>
      <Row>We may share Your personal information in the following situations:</Row>
      <Row>
        <ul>
          <li>
            <strong>With Service Providers:</strong> We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, for payment processing,
            to contact You.
          </li>
          <li>
            <strong>For business transfers:</strong> We may share or transfer Your personal
            information in connection with, or during negotiations of, any merger, sale of Company
            assets, financing, or acquisition of all or a portion of Our business to another
            company.
          </li>
          <li>
            <strong>With Affiliates:</strong> We may share Your information with Our affiliates, in
            which case we will require those affiliates to honor this Privacy Policy. Affiliates
            include Our parent company and any other subsidiaries, joint venture partners or other
            companies that We control or that are under common control with Us.
          </li>
          <li>
            <strong>With business partners:</strong> We may share Your information with Our business
            partners to offer You certain products, services or promotions.
          </li>
          <li>
            <strong>With other users:</strong> when You share personal information or otherwise
            interact in the public areas with other users, such information may be viewed by all
            users and may be publicly distributed outside.
          </li>
          <li>
            <strong>With Your consent</strong>: We may disclose Your personal information for any
            other purpose with Your consent.
          </li>
        </ul>
      </Row>
      <Row>
        <strong>Retention of Your Personal Data</strong>
      </Row>
      <Row>
        The Company will retain Your Personal Data only for as long as is necessary for the purposes
        set out in this Privacy Policy. We will retain and use Your Personal Data to the extent
        necessary to comply with our legal obligations (for example, if we are required to retain
        your data to comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.
      </Row>
      <Row>
        The Company will also retain Usage Data for internal analysis purposes. Usage Data is
        generally retained for a shorter period of time, except when this data is used to strengthen
        the security or to improve the functionality of Our Service, or We are legally obligated to
        retain this data for longer time periods.
      </Row>
      <Row>
        <strong>Transfer of Your Personal Data</strong>
      </Row>
      <Row>
        Your information, including Personal Data, is processed at the Company&apos;s operating
        offices and in any other places where the parties involved in the processing are located. It
        means that this information may be transferred to — and maintained on — computers located
        outside of Your state, province, country or other governmental jurisdiction where the data
        protection laws may differ than those from Your jurisdiction.
      </Row>
      <Row>
        Your consent to this Privacy Policy followed by Your submission of such information
        represents Your agreement to that transfer.
      </Row>
      <Row>
        The Company will take all steps reasonably necessary to ensure that Your data is treated
        securely and in accordance with this Privacy Policy and no transfer of Your Personal Data
        will take place to an organization or a country unless there are adequate controls in place
        including the security of Your data and other personal information.
      </Row>
      <Row>
        <strong>Disclosure of Your Personal Data</strong>
      </Row>
      <Row>
        <strong>Business Transactions</strong>
      </Row>
      <Row>
        If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
        transferred. We will provide notice before Your Personal Data is transferred and becomes
        subject to a different Privacy Policy.
      </Row>
      <Row>
        <strong>Law enforcement</strong>
      </Row>
      <Row>
        Under certain circumstances, the Company may be required to disclose Your Personal Data if
        required to do so by law or in response to valid requests by public authorities (e.g. a
        court or a government agency).
      </Row>
      <Row>
        <strong>Other legal requirements</strong>
      </Row>
      <Row>
        The Company may disclose Your Personal Data in the good faith belief that such action is
        necessary to:
      </Row>
      <Row>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>Protect the personal safety of Users of the Service or the public</li>
          <li>Protect against legal liability</li>
        </ul>
      </Row>
      <Row>
        <strong>Security of Your Personal Data</strong>
      </Row>
      <Row>
        The security of Your Personal Data is important to Us, but remember that no method of
        transmission over the Internet, or method of electronic storage is 100% secure. While We
        strive to use commercially acceptable means to protect Your Personal Data, We cannot
        guarantee its absolute security.
      </Row>
      <Row>
        <strong>Detailed Information on the Processing of Your Personal Data</strong>
      </Row>
      <Row>
        The Service Providers We use may have access to Your Personal Data. These third-party
        vendors collect, store, use, process and transfer information about Your activity on Our
        Service in accordance with their Privacy Policies.
      </Row>
      <Row>
        <strong>Analytics</strong>
      </Row>
      <Row>
        We may use third-party Service providers to monitor and analyze the use of our Service.
      </Row>
      <Row>
        <ul>
          <li>
            <p>
              <strong>Google Analytics</strong>
            </p>
            <p>
              Google Analytics is a web analytics service offered by Google that tracks and reports
              website traffic. Google uses the data collected to track and monitor the use of our
              Service. This data is shared with other Google services. Google may use the collected
              data to contextualize and personalize the ads of its own advertising network.
            </p>
            <p>
              You can opt-out of having made your activity on the Service available to Google
              Analytics by installing the Google Analytics opt-out browser add-on. The add-on
              prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing
              information with Google Analytics about visits activity.
            </p>
            <p>
              For more information on the privacy practices of Google, please visit the Google
              Privacy & Terms web page: https://policies.google.com/privacy
            </p>
          </li>
        </ul>
      </Row>
      <Row>
        <strong>Children&apos;s Privacy</strong>
      </Row>
      <Row>
        Our Service does not address anyone under the age of 13. We do not knowingly collect
        personally identifiable information from anyone under the age of 13. If You are a parent or
        guardian and You are aware that Your child has provided Us with Personal Data, please
        contact Us. If We become aware that We have collected Personal Data from anyone under the
        age of 13 without verification of parental consent, We take steps to remove that information
        from Our servers.
      </Row>
      <Row>
        If We need to rely on consent as a legal basis for processing Your information and Your
        country requires consent from a parent, We may require Your parent&apos;s consent before We
        collect and use that information.
      </Row>
      <Row>
        <strong>Your California Privacy Rights (California&apos;s Shine the Light law)</strong>
      </Row>
      <Row>
        Under California Civil Code Section 1798 (California&apos;s Shine the Light law), California
        residents with an established business relationship with us can request information once a
        year about sharing their Personal Data with third parties for the third parties&apos; direct
        marketing purposes.
      </Row>
      <Row>
        If you&apos;d like to request more information under the California Shine the Light law, and
        if You are a California resident, You can contact Us using the contact information provided
        below.
      </Row>
      <Row>
        <strong>
          California Privacy Rights for Minor Users (California Business and Professions Code
          Section 22581)
        </strong>
      </Row>
      <Row>
        California Business and Professions Code Section 22581 allows California residents under the
        age of 18 who are registered users of online sites, services or applications to request and
        obtain removal of content or information they have publicly posted.
      </Row>
      <Row>
        To request removal of such data, and if You are a California resident, You can contact Us
        using the contact information provided below, and include the email address associated with
        Your account.
      </Row>
      <Row>
        Be aware that Your request does not guarantee complete or comprehensive removal of content
        or information posted online and that the law may not permit or require removal in certain
        circumstances.
      </Row>
      <Row>
        <strong>Links to Other Websites</strong>
      </Row>
      <Row>
        Our Service may contain links to other websites that are not operated by Us. If You click on
        a third party link, You will be directed to that third party&apos;s site. We strongly advise
        You to review the Privacy Policy of every site You visit.
      </Row>
      <Row>
        We have no control over and assume no responsibility for the content, privacy policies or
        practices of any third party sites or services.
      </Row>
      <Row>
        <strong>Changes to this Privacy Policy</strong>
      </Row>
      <Row>
        We may update Our Privacy Policy from time to time. We will notify You of any changes by
        posting the new Privacy Policy on this page.
      </Row>
      <Row>
        We will let You know via email and/or a prominent notice on Our Service, prior to the change
        becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy
        Policy.
      </Row>
      <Row>
        You are advised to review this Privacy Policy periodically for any changes. Changes to this
        Privacy Policy are effective when they are posted on this page.
      </Row>
      <Row>
        <strong>Contact Us</strong>
      </Row>
      <Row>If you have any questions about this Privacy Policy, You can contact us:</Row>
      <Row>
        <ul>
          <li>
            By visiting this page on our website:{" "}
            <Link to="/contact" target="_blank">
              https://brandizby.com/contact
            </Link>
          </li>
          <li>
            By sending us an email:
            <a href="mailto:andrea@brandizby.com"> andrea@brandizby.com</a>
          </li>
          <li>
            By calling us on:
            <span> +421 940 321 643</span>
          </li>
        </ul>
      </Row>
    </Col>
  </Container>
);

export default Policy;
