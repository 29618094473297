import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import clsx from "clsx";

import { Nav } from "react-bootstrap";
import isActiveClass from "helpers/isActiveClass";

import "./NavItem.scss";

const NavItem = ({ to, onClick, className, children }) => {
  const pathname = `/${to.toLowerCase()}`;

  return (
    <Nav.Item className={className}>
      <Link
        className={clsx("nav-link cursor-pointer", isActiveClass(pathname))}
        to={pathname}
        smooth="easeInOutQuart"
        onClick={onClick}
      >
        {children || to}
      </Link>
    </Nav.Item>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

NavItem.defaultProps = {
  className: "",
  to: "",
  onClick: null,
  children: null,
};

export default NavItem;
