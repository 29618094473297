import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { Col } from "react-bootstrap";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import options from "../../utils/richTextOptions";

import PrimaryButton from "../PrimaryButton";

import "./PortfolioItem.scss";

const PortfolioItem = ({ slug, title, coverImage, shortDescription }) => {
  return (
    <>
      <Col md={7} className="portfolio-item">
        <div className="portfolio-card">
            <div className="card-side front">
              <GatsbyImage
                alt={title}
                image={coverImage.gatsbyImageData}
                className="card-picture img-fluid"
              />
              <h3 className="card-heading">{title}</h3>
            </div>
            <div className="card-side back">
              <div className="portfolio-caption markdown">
                <h3 className="card-heading">{title}</h3>
                <div className="pt-5">{renderRichText(shortDescription, options)}</div>
                <PrimaryButton text="Check it out" link={slug} />
              </div>
            </div>
        </div>
      </Col>
    </>
  );
};

PortfolioItem.propTypes = {
  slug: PropTypes.string.isRequired,
  coverImage: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.object,
};

PortfolioItem.defaultProps = {
  shortDescription: {},
};

export default PortfolioItem;
