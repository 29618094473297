import React from "react";
import { graphql, Link } from "gatsby";

import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

import PageWrapper from "components/PageWrapper";

import "../style/pages/careers.scss";

const careers = ({ data }) => {
  if (!data) {
    return null;
  }

  const {
    careers: { nodes: careersList },
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: { header, subheader, imageFileName },
          },
        },
      ],
    },
  } = data;

  return (
    <PageWrapper header={header} subheader={subheader} imageFileName={imageFileName}>
      <Table striped hover>
        <thead>
          <tr className="careers-tr">
            <th colSpan="4">
              <h4>Featured Jobs</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          {careersList.map((job) => (
            <tr key={job.slug} className="careers-tr">
              <td className="careers-td">{job.title}</td>
              <td className="careers-td d-none d-md-table-cell">
                {job.partTime ? `part-time` : `full-time`}
              </td>
              <td className="careers-td d-none d-md-table-cell">{job.location || `remote`}</td>
              <td className="careers-td">
                <Link
                  role="button"
                  className="btn btn-outline-secondary"
                  to={`/careers/${job.slug}`}
                >
                  <span className="button-text">Apply Now</span>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </PageWrapper>
  );
};

export const query = graphql`
  {
    careers: allContentfulCareers {
      nodes {
        slug
        title
        location
        partTime
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: "en" }, fileName: { eq: "Careers.en" } } }
    ) {
      edges {
        node {
          frontmatter {
            header
            subheader
            imageFileName
          }
        }
      }
    }
  }
`;

careers.propTypes = {
  data: PropTypes.object,
};

careers.defaultProps = {
  data: null,
};

export default careers;
