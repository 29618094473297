import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import options from "../../../utils/richTextOptions";
import Icon from "../../../components/Icon";

import "./Color.scss";
import "../Project.scss";

const Color = ({ colorsPalletDescription, colorsPalletImage, alt }) => {
  return (
    <Row className="project-color">
      <Col md={4}>
        <div className="position-center pt-5">
          <div className="markdown pt-5">{renderRichText(colorsPalletDescription, options)}</div>
        </div>
      </Col>
      <Col md={8} className="palette-col">
        <GatsbyImage alt={alt} image={colorsPalletImage.gatsbyImageData} className="color-image" />
        <Icon iconName="ArrowRight" className="arrow-icon fa-lg d-block d-lg-none" size="3x" />
      </Col>
    </Row>
  );
};

Color.propTypes = {
  colorsPalletDescription: PropTypes.object.isRequired,
  colorsPalletImage: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Color;
