import React, { useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { Form, Button, Row } from "react-bootstrap";

import CustomAlert from "components/CustomAlert";

import "./ContactForm.scss";

const ContactForm = ({ career }) => {
  const [formData, setFormData] = useState({
    title: career || "",
    email: "",
    message: "",
    resume: {},
    portfolio: {},
    file: {},
  });

  const [status, setStatus] = useState("");
  const actionPage = "/contact";

  const handleChange = (event) => {
    const key = event.target.name;
    let updatedFormValue;
    if (event.target.files) {
      updatedFormValue = event.target.files[0];
    } else {
      updatedFormValue = event.target.value;
    }

    const newFormData = { ...formData, [key]: updatedFormValue };
    setFormData(newFormData);
  };

  const clearStatus = () => {
    setStatus("");
  };

  const encode = (body) => {
    const fd = new FormData();
    Object.keys(body).forEach((k) => {
      fd.append(k, body[k]);
    });
    return fd;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setStatus("info");
    const form = event.target;
    const body = encode({ "form-name": form.getAttribute("name"), ...formData });

    try {
      const response = await axios.post(actionPage, body, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        setStatus("success");
        setFormData({ title: "", email: "", message: "", resume: {}, portfolio: {}, file: {} });
      }
    } catch (error) {
      setStatus("danger");
    }
  };

  return (
    <>
      {status ? (
        <CustomAlert variant={status} clearStatus={clearStatus} />
      ) : (
        <Form
          name="contact"
          id="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          encType="multipart/form-data"
          onSubmit={(e) => handleSubmit(e)}
          action={actionPage}
          className="contact-form"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div hidden>
            <label htmlFor="bot-field">
              Don&apos;t fill this out: <input name="bot-field" />
            </label>
          </div>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              required
              type="text"
              name="title"
              value={formData.title}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              required
              type="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={(e) => handleChange(e)}
            />
            <Form.Text className="text-muted">
              We&apos;ll never share your email with anyone.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Message</Form.Label>
            <Form.Control
              required
              as="textarea"
              rows="12"
              name="message"
              value={formData.message}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>

          <div className={!career ? "hidden-fields" : null}>
            <Form.Group className="mb-3">
              <Form.Label>Upload resume</Form.Label>
              <Form.Control
                type="file"
                name="resume"
                accept=".png,.jpg,.jpeg,.webp,.pdf,.docx,.xlsx"
                onChange={(e) => handleChange(e)}
                required={career}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                Upload portfolio <small>(optional)</small>
              </Form.Label>
              <Form.Control
                type="file"
                name="portfolio"
                accept=".png,.jpg,.jpeg,.webp,.pdf,.docx,.xlsx"
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </div>

          <Form.Group className="mb-3">
            <Form.Label>
              Upload {career ? `cover letter` : `file`} <small>(optional)</small>
            </Form.Label>
            <Form.Control
              type="file"
              name="file"
              accept=".png,.jpg,.jpeg,.webp,.pdf,.docx,.xlsx"
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>

          <Row className="pt-5">
            <Button variant="btn btn-outline-primary" type="submit" name="submit">
              <span className="button-text" data-text="Submit" />
            </Button>
          </Row>
        </Form>
      )}
    </>
  );
};

ContactForm.propTypes = {
  career: PropTypes.string,
};

ContactForm.defaultProps = {
  career: "",
};

export default ContactForm;
