import React from "react";
import PropTypes from "prop-types";

import "./ServiceItemImage.scss";

const ServiceItemImage = ({ header, imageFileName }) => {
  return (
    <img
      src={`/images/services/${imageFileName}`}
      alt={`${header.toLowerCase()}-icon`}
      className="service-item-image"
    />
  );
};

ServiceItemImage.propTypes = {
  header: PropTypes.string.isRequired,
  imageFileName: PropTypes.string.isRequired,
};

export default ServiceItemImage;
