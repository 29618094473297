import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";

import "../style/pages/error.scss";

const NotFoundPage = ({ data }) => {
  if (!data) {
    return null;
  }

  const {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: {
              anchor,
              header: rootHeader,
              subheader: rootSubHeader,
              imageFileName,
              buttons,
            },
          },
        },
      ],
    },
  } = data;

  return (
    <Container className="error-container">
      <Row>
        <h2 className="text-center">{rootHeader}</h2>
      </Row>
      <Row>
        <Col md={6}>
          <img src={`/images/${imageFileName}`} alt={anchor} className="error-icon" />
        </Col>
        <Col md={6} className="error-text">
          <p className="pt-3">{rootSubHeader}</p>
          <Row className="pt-5">
            {buttons.map((button) => (
              <Col md={6} xs={6} key={button.text} className="text-center">
                <Link role="button" className="btn btn-outline-secondary" to={`#${button.link}`}>
                  <span className="button-text">{button.text}</span>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(filter: { fields: { langKey: { eq: "en" }, fileName: { eq: "Error.en" } } }) {
      edges {
        node {
          frontmatter {
            anchor
            header
            subheader
            imageFileName
            buttons {
              text
              link
            }
          }
        }
      }
    }
  }
`;

NotFoundPage.propTypes = {
  data: PropTypes.object,
};

NotFoundPage.defaultProps = {
  data: null,
};

export default NotFoundPage;
