import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Navbar, Container, Nav } from "react-bootstrap";

import useWindowOnScroll from "hooks/useWindowOnScroll";
import Image from "components/Image";
import NavDropdown from "components/NavDropdown";
import NavItem from "components/NavItem";

import menuIcon from "../../../content/assets/images/menu_icon.svg";
import "./Navbar.scss";

const MyNavbar = ({ frontmatter }) => {
  const { brand, logo, links } = frontmatter;
  const [expanded, setExpanded] = React.useState(false);

  const toggleMenu = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const closeMenu = React.useCallback(() => {
    setExpanded(false);
  }, []);

  const [shrink, setShrink] = React.useState(false);

  const handleWindowScroll = React.useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setShrink(scrollTop > 100);
  }, []);

  useWindowOnScroll(handleWindowScroll);

  return (
    <Navbar
      className={clsx("navbar-root", { "navbar-shrink": shrink })}
      expand="lg"
      fixed="top"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand className="cursor-pointer" href="/">
          <Image fileName={logo} alt={brand} width="500" height="500" />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={toggleMenu}
          aria-label="Toggle navigation"
          style={{ backgroundImage: `url(${menuIcon})` }}
        />
        <Navbar.Collapse>
          <Nav className="ml-auto">
            {links &&
              links.map((link) =>
                link === "Portfolio" ? (
                  <NavDropdown key={link} onClick={closeMenu} />
                ) : (
                  <NavItem key={link} to={link} onClick={closeMenu} />
                ),
              )}
          </Nav>
          {/* add extraItems for language selector */}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

MyNavbar.propTypes = {
  frontmatter: PropTypes.object,
};

MyNavbar.defaultProps = {
  frontmatter: {},
};

export default MyNavbar;
