import React from "react";
import PropTypes from "prop-types";

import BGimage from "static/background/bi-bg.svg";

const BGWrapper = ({ children }) => (
  <div className="page-bg" style={{ backgroundImage: `url(${BGimage})` }}>
    {children}
  </div>
);

BGWrapper.propTypes = {
  children: PropTypes.array.isRequired,
};

export default BGWrapper;
