const isActiveClass = (path) => {
  const isBrowser = () => typeof window !== "undefined";
  const windowPathname = isBrowser() && window.location.pathname;

  if (windowPathname && windowPathname.includes(path)) {
    return "active";
  }
  return "";
};

export default isActiveClass;
