import React from "react";
import { Link, graphql } from "gatsby";
import PropTypes from "prop-types";

import { Col, Row, Button } from "react-bootstrap";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import PageWrapper from "components/PageWrapper";
import SEO from "../../components/SEO";
import Icon from "../../components/Icon";

import options from "../../utils/richTextOptions";
import "../../style/pages/career.scss";

const CareerTemplate = ({ data }) => {
  if (!data) {
    return null;
  }

  const {
    career,
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: { header, subheader, imageFileName },
          },
        },
      ],
    },
  } = data;

  return (
    <>
      <SEO
        title={career.title}
        description={career.description.raw}
        pathname={`/careers/${career.slug}`}
      />
      <PageWrapper header={header} subheader={subheader} imageFileName={imageFileName}>
        <Row className="title-row">
          <Link className="careers-link" to="/careers">
            Back to careers
          </Link>
          <h2 className="text-center">{career.title}</h2>
        </Row>
        <Row>
          <Col md={8}>
            <div className="markdown">{renderRichText(career.description, options)}</div>
            <Row>
              <Link to="/careers/apply" state={{ career: career.title }}>
                <Button variant="btn btn-full btn-outline-primary">
                  <span className="button-text" data-text="Apply Now" />
                </Button>
              </Link>
            </Row>
          </Col>
          <Col md={{ offset: 1, span: 3 }} className="details-col d-none d-md-block">
            <div>
              <Link to="/careers/apply" state={{ career: career.title }}>
                <Button variant="btn btn-full btn-outline-primary">
                  <span className="button-text" data-text="Apply Now" />
                </Button>
              </Link>
            </div>
            <div className="career-box">
              <div className="career-box-item">
                <Icon iconName="BusinessHours" className="arrow-icon fa-lg career-icon" />
                <span
                  className="career-text"
                  data-text={career.partTime ? "part-time" : "full-time"}
                />
              </div>
              <div className="career-box-item">
                <Icon iconName="Location" className="arrow-icon fa-lg career-icon" />
                <span className="career-text" data-text={career.location || "remote"} />
              </div>
            </div>
          </Col>
        </Row>
      </PageWrapper>
    </>
  );
};

export const query = graphql`
  query CareerQuery($title: String) {
    career: contentfulCareers(title: { eq: $title }) {
      slug
      title
      location
      partTime
      description {
        raw
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: "en" }, fileName: { eq: "Career.en" } } }
    ) {
      edges {
        node {
          frontmatter {
            header
            subheader
            imageFileName
          }
        }
      }
    }
  }
`;

CareerTemplate.propTypes = {
  data: PropTypes.object,
};

CareerTemplate.defaultProps = {
  data: {},
};

export default CareerTemplate;
