import React from "react";
import PropTypes from "prop-types";

import "./VimeoVideo.scss";

const VimeoVideo = ({ videoId, videoTitle }) => (
  <div className="video embed-responsive embed-responsive-16by9">
    <iframe
      src={`https://player.vimeo.com/video/${videoId}?h=3321f54f45&muted=1&autoplay=1&loop=1`}
      frameBorder="3"
      allow="autoplay; muted; picture-in-picture"
      title={videoTitle}
      className="embed-responsive-item"
    />
  </div>
);

VimeoVideo.propTypes = {
  videoId: PropTypes.number.isRequired,
  videoTitle: PropTypes.string.isRequired,
};

export default VimeoVideo;
