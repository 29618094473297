import React from "react";
import PropTypes from "prop-types";

import { Container, Row } from "react-bootstrap";

import Banner from "../Banner";

const PageWrapper = ({ children, className, header, subheader, imageFileName }) => {
  return (
    <>
      <Row>
        <Banner header={header} subheader={subheader} imageFileName={imageFileName} />
      </Row>
      <Container className={`page-container ${className}`}>{children}</Container>
    </>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  imageFileName: PropTypes.string,
};

PageWrapper.defaultProps = {
  children: null,
  className: null,
  header: "",
  subheader: "",
  imageFileName: "",
};

export default PageWrapper;
