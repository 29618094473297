import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import clsx from "clsx";

import { Container, Row, Col } from "react-bootstrap";
import ContactItem from "components/ContactItem";
import "./Contact.scss";

const Contact = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      contact: allMarkdownRemark(filter: { fields: { fileName: { eq: "Contact.en" } } }) {
        nodes {
          frontmatter {
            anchor
            header
            subheader
            contactItems {
              iconFileName
              label
              text
              link
            }
          }
        }
      }
    }
  `);

  if (!data) {
    return null;
  }

  const {
    contact: {
      nodes: [
        {
          frontmatter: { anchor, header, subheader, contactItems },
        },
      ],
    },
  } = data;

  return (
    <Container className={clsx("contact-container bg-light", className)} id={anchor}>
      <Row className="justify-content-center contact-header">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider my-4" />
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>
      <Row className="contact-info">
        {contactItems.map((contactItem) => {
          return <ContactItem key={contactItem.label} {...contactItem} />;
        })}
      </Row>
    </Container>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
};

Contact.defaultProps = {
  className: null,
};

export default Contact;
