import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Vimeo = ({ userName }) => (
  <CircleIcon href={`https://vimeo.com/${userName}`} iconName="VimeoIcon" />
);

Vimeo.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Vimeo;
