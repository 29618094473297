import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import options from "../../../utils/richTextOptions";
import "./Description.scss";
import "../Project.scss";

const Description = ({ projectDescription, projectDetails, primaryColor }) => {
  return (
    <Row className="project-description">
      <Col md={7}>
        <div className="position-center">
          <div className="markdown">{renderRichText(projectDescription, options)}</div>
        </div>
      </Col>
      <Col md={5} style={{ backgroundColor: primaryColor }}>
        <div className="position-center">
          <div className="markdown">{renderRichText(projectDetails, options)}</div>
        </div>
      </Col>
    </Row>
  );
};

Description.propTypes = {
  projectDescription: PropTypes.object.isRequired,
  projectDetails: PropTypes.object.isRequired,
  primaryColor: PropTypes.string.isRequired,
};

export default Description;
