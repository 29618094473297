import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Client from "components/Client";

import "./Clients.scss";

const Clients = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      clientsSection: allMarkdownRemark(
        filter: { fields: { directoryName: { eq: "sections" }, fileName: { eq: "Clients.en" } } }
      ) {
        edges {
          node {
            frontmatter {
              anchor
              header
              subheader
              clients {
                href
                imageFileName
              }
            }
          }
        }
      }
    }
  `);

  if (!data) {
    return null;
  }

  const {
    clientsSection: {
      edges: [
        {
          node: {
            frontmatter: { anchor, header, subheader, clients },
          },
        },
      ],
    },
  } = data;

  return (
    <PageSection className={clsx("py-5", className)} id={anchor}>
      <Row>
        <SectionHeader header={header} subheader={subheader} />
      </Row>
      <Row className="clients-content">
        {clients.map(({ href, imageFileName }) => (
          <Col lg={3} sm={6} className="client-item" key={imageFileName}>
            <Client href={href} imageFileName={imageFileName} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Clients.propTypes = {
  className: PropTypes.string,
};

Clients.defaultProps = {
  className: null,
};

export default Clients;
