import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import sanitizeHtml from "sanitize-html";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import options from "../../../utils/richTextOptions";

import "./Font.scss";

const Font = ({
  headerFontName,
  headerFontWeight,
  headerFontUrl,
  subheaderFontName,
  subheaderFontWeight,
  subheaderFontUrl,
  bodyFontName,
  bodyFontWeight,
  bodyFontUrl,
  bodyText,
  primaryColor,
  secondaryColor,
}) => {
  const fontFace = `
        @font-face {
            font-family: 'Header-Font';
            src: url(${headerFontUrl});
            font-display: swap;
        }
        @font-face {
            font-family: 'Subheader-Font';
            src: url(${subheaderFontUrl});
            font-display: swap;
        }
        @font-face {
            font-family: 'Body-Font';
            src: url(${bodyFontUrl});
            font-display: swap;
        }
      `;

  return (
    <Row className="typography-section pt-5">
      <style
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(fontFace, { allowedTags: ["@font-face", "font-family", "src"] }),
        }}
      />
      <Col md={5} className="typography-heading header-style">
        <h4 className="text-center">Typography</h4>
        <div className="letters">
          <div className=" primary-uppercase" style={{ color: primaryColor }}>
            A
          </div>
          <div className=" primary-lowercase" style={{ color: secondaryColor }}>
            a
          </div>
        </div>
      </Col>
      <Col md={7}>
        <Row className="font-row header-style">
          <Col md={6}>Primary: {headerFontName}</Col>
          <Col md={6}>Weight: {headerFontWeight}</Col>
        </Row>
        {subheaderFontName && (
          <Row className="font-row subheader-style">
            <Col md={6}>Secondary: {subheaderFontName}</Col>
            <Col md={6}>Weight: {subheaderFontWeight}</Col>
          </Row>
        )}
        <Row className="font-row body-style">
          <Col md={6}>Body: {bodyFontName}</Col>
          <Col md={6}>Weight: {bodyFontWeight}</Col>
        </Row>
        <Row className="font-row">
          <Col md={10} className="text-justify">
            {renderRichText(bodyText, options)}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Font.propTypes = {
  headerFontName: PropTypes.string.isRequired,
  headerFontWeight: PropTypes.string.isRequired,
  headerFontUrl: PropTypes.string.isRequired,
  subheaderFontName: PropTypes.string,
  subheaderFontWeight: PropTypes.string,
  subheaderFontUrl: PropTypes.string,
  bodyFontName: PropTypes.string.isRequired,
  bodyFontWeight: PropTypes.string.isRequired,
  bodyFontUrl: PropTypes.string.isRequired,
  bodyText: PropTypes.object.isRequired,
  primaryColor: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
};

Font.defaultProps = {
  subheaderFontName: "",
  subheaderFontWeight: "",
  subheaderFontUrl: "",
};

export default Font;
