import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import clsx from "clsx";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import AboutItem from "components/AboutItem";

import "./About.scss";

const About = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      aboutSection: allMarkdownRemark(
        filter: { fields: { directoryName: { eq: "sections" }, fileName: { eq: "About.en" } } }
      ) {
        edges {
          node {
            frontmatter {
              anchor
              header
              subheader
              aboutItems {
                text
                label
                iconFileName
              }
            }
          }
        }
      }
    }
  `);

  if (!data) {
    return null;
  }

  const {
    aboutSection: {
      edges: [
        {
          node: {
            frontmatter: { anchor, header, subheader, aboutItems },
          },
        },
      ],
    },
  } = data;

  return (
    <PageSection className={clsx("py-5", className)} id={anchor}>
      <Row>
        <SectionHeader header={header} subheader={subheader} />
      </Row>
      <Row className="about-content">
        {aboutItems.map((aboutItem) => {
          return <AboutItem key={aboutItem.label} {...aboutItem} />;
        })}
      </Row>
    </PageSection>
  );
};

About.propTypes = {
  className: PropTypes.string,
};

About.defaultProps = {
  className: "",
};

export default About;
