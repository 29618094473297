import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import useSmoothScrollTo from "hooks/useSmoothScrollTo";

import { Container, Row, Col } from "react-bootstrap";
import * as SocialIcons from "components/SocialIcons";
import Icon from "../../components/Icon";
import Contact from "./Contact";
import "./Footer.scss";

const Footer = ({ frontmatter }) => {
  const handleScrollToTop = useSmoothScrollTo(0);

  const handleTopClick = React.useCallback(() => {
    handleScrollToTop();
  }, [handleScrollToTop]);

  if (!frontmatter) {
    return null;
  }

  const {
    copyright,
    privacyHref,
    privacyText,
    social: { instagram, facebook, linkedin, vimeo },
  } = frontmatter;

  return (
    <footer className="footer">
      <Contact />
      <Container>
        <Row className="pt-5">
          <Col md={6}>
            <p className="text-center text-md-left">{copyright}</p>
          </Col>
          {/* PUT BACK ONCE SOCIAL MEDIA IS READY 
          <Col md={4} className="my-3 my-lg-0 text-center">
            {instagram ? <SocialIcons.Instagram userName={instagram} /> : null}
            {facebook ? <SocialIcons.Facebook userName={facebook} /> : null}
            {linkedin ? <SocialIcons.Linkedin userName={linkedin} /> : null}
            {vimeo ? <SocialIcons.Vimeo userName={vimeo} /> : null}
            <span className="circle-icon">
              <Icon
                iconName="ArrowCircleUp"
                className="arrow-icon fa-lg"
                onClick={handleTopClick}
              />
            </span>
          </Col> */}
          <Col md={6} className="privacy-col text-center text-md-right">
            <Link to={privacyHref}>{privacyText}</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
