import React from "react";
import PropTypes from "prop-types";

import "./ServiceItemText.scss";

const ServiceItemText = ({ content, items }) => (
  <div className="service-item-text text-muted">
    <p>{content}</p>
    {items && (
      <ul>
        {items.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    )}
  </div>
);

ServiceItemText.propTypes = {
  content: PropTypes.string,
  items: PropTypes.array,
};

ServiceItemText.defaultProps = {
  content: "",
  items: [],
};

export default ServiceItemText;
