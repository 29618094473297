import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Reddit = ({ userName }) => (
  <CircleIcon href="https://www.reddit.com/" iconName="RedditIcon" />
);

Reddit.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Reddit;
