import React from "react";
import PropTypes from "prop-types";

import HeaderVideo from "./HeaderVideo";
import PrimaryButton from "../PrimaryButton";

import "./Header.scss";

const Header = ({ header, subheader, linkTo, linkToText, videoFileNames, imageFileNames }) => {
  return (
    <header className="header bg-light">
      <div className="video-box">
        <HeaderVideo videoFileNames={videoFileNames} imageFileNames={imageFileNames} />
      </div>
      <div className="text-box">
        <h4 className="intro-sub">{subheader}</h4>
        <h1 className="intro-heading">{header}</h1>
        {linkTo && <PrimaryButton text={linkToText} link={linkTo} />}
      </div>
    </header>
  );
};

Header.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  linkTo: PropTypes.string,
  linkToText: PropTypes.string,
  videoFileNames: PropTypes.object,
  imageFileNames: PropTypes.object,
};

Header.defaultProps = {
  header: undefined,
  subheader: undefined,
  linkTo: "",
  linkToText: "",
  videoFileNames: {},
  imageFileNames: {},
};

export default Header;
