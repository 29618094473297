import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import clsx from "clsx";

import { NavDropdown } from "react-bootstrap";
import isActiveClass from "helpers/isActiveClass";

import "./NavDropdown.scss";

const MyNavDropdown = ({ onClick }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulProjects {
        nodes {
          title
          slug
        }
      }
    }
  `);

  const {
    allContentfulProjects: { nodes: navPortfolio },
  } = data;

  return (
    <NavDropdown title="Portfolio" id="collasible-nav-dropdown">
      {navPortfolio &&
        navPortfolio.map((navProject) => (
          <NavDropdown.Item
            className={clsx("nav-link cursor-pointer", isActiveClass(navProject.slug))}
            key={navProject.slug}
            href={`/${navProject.slug}`}
            smooth="easeInOutQuart"
            onClick={onClick}
          >
            {navProject.title}
          </NavDropdown.Item>
        ))}
    </NavDropdown>
  );
};

MyNavDropdown.propTypes = {
  onClick: PropTypes.func,
};

MyNavDropdown.defaultProps = {
  onClick: null,
};

export default MyNavDropdown;
