import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import options from "../../../utils/richTextOptions";
import "../Project.scss";

const Client = ({ clientDescription, clientImage, clientLogo, alt, primaryColor }) => {
  return (
    <Row className="project-client">
      <Col md={5} style={{ backgroundColor: primaryColor }}>
        <Row>
          <GatsbyImage alt={alt} image={clientImage.gatsbyImageData} />
        </Row>
        <Row>
          <div className="position-center pt-5">
            <div className="markdown pt-5">{renderRichText(clientDescription, options)}</div>
          </div>
        </Row>
      </Col>
      <Col md={7} className="bg-white">
        <div className="position-center">
          <GatsbyImage alt={alt} image={clientLogo.gatsbyImageData} />
        </div>
      </Col>
    </Row>
  );
};

Client.propTypes = {
  clientDescription: PropTypes.object.isRequired,
  clientImage: PropTypes.object.isRequired,
  clientLogo: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  primaryColor: PropTypes.string.isRequired,
};

export default Client;
