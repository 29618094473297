import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Col, Row } from "react-bootstrap";

import Image from "components/Image";
import Icon from "../Icon";

import "./Banner.scss";

const Banner = ({ header, subheader, linkTo, linkToText, imageFileName }) => {
  return (
    <Row className="banner bg-light">
      <Col md={6} className="text-col d-flex align-items-center justify-content-center">
        <div className="text-wrapper">
          <h4 className="intro-sub">{subheader}</h4>
          <h1 className="intro-heading">{header}</h1>
          {linkTo && (
            <div className="button-wrapper">
              <Link role="button" className="btn btn-outline-primary" to={linkTo}>
                <span className="button-text" data-text={linkToText} />
                <Icon iconName="ArrowRight" className="arrow-icon fa-lg" />
              </Link>
            </div>
          )}
        </div>
      </Col>
      <Col md={6} className="image-col d-flex align-items-center justify-content-center">
        <div className="image-wrapper">
          <Image
            className="banner-image"
            fileName={imageFileName}
            alt="services-banner-image"
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      </Col>
    </Row>
  );
};

Banner.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  linkTo: PropTypes.string,
  linkToText: PropTypes.string,
  imageFileName: PropTypes.string,
};

Banner.defaultProps = {
  header: undefined,
  subheader: undefined,
  linkTo: "",
  linkToText: "",
  imageFileName: "",
};

export default Banner;
